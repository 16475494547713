/*
Author: Kamakhya Pandey
Created: 
Last updated: 02-Feb-2021
Purpose: Visualizer Component
Visualizer component displays the content of current page in an iFrame. 
Multiples functions can be performed on the content of the page and the iFrame itself.
Functionalities are controlled by the options available under ToolBar child component.
*/
import React, { Component } from 'react';
import ToolBar from './ToolBar'
import './css/Visualizer.css';

export default class Visualizer extends Component {
    state = {
        visualizerDefaultWidth: 0,
        visualizerDefaultHeight: 0,
        visualizerDefaultScale: 1,
        visualizerCurrentWidth: 0,
        visualizerCurrentHeight: 0,
        visualizerCurrentScale: 1,
        visualizerZoomMultiplier: 10,
        visualizerZoomMin: 0.25,        
       	visualizerZoomMax: 2,
        visualizerZoomStep: 0.25,
        visualizerScaleUOM: '%',
        visualizerInnerHTML: '',
    }

    componentDidMount() {
        this.setDefaultFrameSize(100, 100);
    }

    setDefaultFrameSize = (width, height) => {
        this.setState({
            visualizerDefaultWidth: width,
            visualizerDefaultHeight: height,
            visualizerCurrentWidth: width,
            visualizerCurrentHeight: height
        }, () => {
            /*
            console.log(this.state.visualizerDefaultWidth.toString() + " | " + this.state.visualizerDefaultHeight.toString() + " | " + this.state.visualizerDefaultScale.toString() + " | " +
                this.state.visualizerCurrentWidth.toString() + " | " + this.state.visualizerCurrentHeight.toString() + " | " + this.state.visualizerCurrentScale.toString())
            */
        });
    }

    zoomIn = () => {
        if (this.state.visualizerCurrentScale <= (this.state.visualizerZoomMax - this.state.visualizerZoomStep)) {
            const multiplier = parseFloat((this.state.visualizerCurrentScale + this.state.visualizerZoomStep).toFixed(2));

            this.setState(prevState => ({
                visualizerCurrentWidth: parseFloat((prevState.visualizerDefaultWidth / multiplier).toFixed(2)),
                visualizerCurrentHeight: parseFloat((prevState.visualizerDefaultHeight / multiplier).toFixed(2)),
                visualizerCurrentScale: multiplier
            }), () => {
                console.log(this.state.visualizerDefaultWidth.toString() + " | " + this.state.visualizerDefaultHeight.toString() + " | " + this.state.visualizerDefaultScale.toString() + " | " +
                    this.state.visualizerCurrentWidth.toString() + " | " + this.state.visualizerCurrentHeight.toString() + " | " + this.state.visualizerCurrentScale.toString())
            });
        }
    }

    zoomOut = () => {
        if (this.state.visualizerCurrentScale >= (this.state.visualizerZoomMin + this.state.visualizerZoomStep)) {
            const multiplier = parseFloat((this.state.visualizerCurrentScale - this.state.visualizerZoomStep).toFixed(2));

            this.setState(prevState => ({
                visualizerCurrentWidth: parseFloat((prevState.visualizerDefaultWidth / multiplier).toFixed(2)),
                visualizerCurrentHeight: parseFloat((prevState.visualizerDefaultHeight / multiplier).toFixed(2)),
                visualizerCurrentScale: multiplier
            }), () => {
                console.log(this.state.visualizerDefaultWidth.toString() + " | " + this.state.visualizerDefaultHeight.toString() + " | " + this.state.visualizerDefaultScale.toString() + " | " +
                    this.state.visualizerCurrentWidth.toString() + " | " + this.state.visualizerCurrentHeight.toString() + " | " + this.state.visualizerCurrentScale.toString())
            });
        }
    }

    zoomReset = () => {
        this.setState(prevState => ({
            visualizerCurrentWidth: prevState.visualizerDefaultWidth,
            visualizerCurrentHeight: prevState.visualizerDefaultHeight,
            visualizerCurrentScale: prevState.visualizerDefaultScale,
            visualizerZoomMultiplier: 10
        }), () => {
            console.log(this.state.visualizerDefaultWidth.toString() + " | " + this.state.visualizerDefaultHeight.toString() + " | " + this.state.visualizerDefaultScale.toString() + " | " +
                this.state.visualizerCurrentWidth.toString() + " | " + this.state.visualizerCurrentHeight.toString() + " | " + this.state.visualizerCurrentScale.toString())
        });
    }

    textSearch = async () => {
        var iframe = document.getElementById("fileContainer").contentDocument.body;
        var returnValue = await this.props.visualizerSearch(iframe.innerHTML);
        iframe.innerHTML = returnValue;
    }

    prevMatch = async () => {
        if (this.props.visualizerSearchMatchCount > 0) {
            var iframe = document.getElementById("fileContainer").contentDocument.body;
            var returnValue = await this.props.decrementVisualizerMatchPosition();
            iframe.innerHTML = returnValue;
        }
    }

    nextMatch = async () => {
        if (this.props.visualizerSearchMatchCount > 0) {
            var iframe = document.getElementById("fileContainer").contentDocument.body;
            var returnValue = await this.props.incrementVisualizerMatchPosition();
            iframe.innerHTML = returnValue;
        }
    }

    render() {
        let curWidth = this.state.visualizerCurrentWidth + this.state.visualizerScaleUOM;
        let curHeight = this.state.visualizerCurrentHeight + this.state.visualizerScaleUOM;

        return (
            <div id="visualizerWrapper" className="border rounded border-dark">
                <ToolBar currentPageNo={this.props.currentPageNo} totalPages={this.props.totalPages}
                    firstPage={this.props.firstPage} prevPage={this.props.prevPage} nextPage={this.props.nextPage}
                    lastPage={this.props.lastPage} goToPage={this.props.goToPage} handleMatchCase={this.handleMatchCase}
                    totalMatchCount={this.state.searchTextMatchCount} currentMatchPosition={this.state.currentMatchPosition} 
                    textSearch={this.textSearch} prevMatch={this.prevMatch} nextMatch={this.nextMatch}
                    zoomIn={this.zoomIn} zoomOut={this.zoomOut} zoomReset={this.zoomReset} srLoading={this.props.srLoading}
                    downloadFilePage={this.props.downloadFilePage} visualizerSearchText={this.props.visualizerSearchText}
                    visualizerSearchMatchCount={this.props.visualizerSearchMatchCount} visualizerSearchCurrentMatchPosition={this.props.visualizerSearchCurrentMatchPosition} 
                    visualizerSearchMatchCase={this.props.visualizerSearchMatchCase}
                    handleVisualizerSearchTextChange={this.props.handleVisualizerSearchTextChange} setVisualizerSearchMatchCase={this.props.setVisualizerSearchMatchCase} 
                    visualizerDisableSearch={this.props.visualizerDisableSearch}
                />
                <div className="visualizerContainer">
                    <iframe id="fileContainer" className="visualizer" src={this.props.currentPageUrl} title="Visualizer" frameBorder="0"
                        style={{ width: curWidth, height: curHeight, transform: `scale(${this.state.visualizerCurrentScale})` }} >Not supported</iframe>
                </div>
            </div>
        )
    }
}
/*
*/
