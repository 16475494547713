import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'
import { Menu, Dropdown, Icon, } from 'semantic-ui-react'

export default class NavMenu extends Component {
    state = {
        userName: '',
        userGivenName: '',
        activeItem: 'search',
        userMenuValue: ''
    }

    componentDidMount() {
        let apiURL = "/api/User";

        this.props.callAPI(apiURL, 'GET', 'json').then(data => {
            this.setState({
                userName: data.name,
                userGivenName: data.givenName,
            });
        }).catch(error => {
            console.error(error);
        });
    }

    handleItemClick = (e, { name }) => {
        this.setState({ activeItem: name })
    }

    handleUserAction = (e, { value }) => {
        console.log("User action : " + value);
        switch (value) {
            case 'sign-out':
                if (window.confirm("Do you want to sign out?")) {
                    this.props.executeLogout();
                }
                break;
            default:
                break;
        }
    };

    render() {
        const userTrigger = (
            <span title={"Signed in as " + this.state.userName}>
                <Icon name='user' /> Hello, {this.state.userGivenName}
            </span>
        )

        const userOptions = [
            { key: 'profile', text: 'User Profile', value: 'profile', disabled: true },
            { key: 'sign-out', text: 'Sign Out', value: 'sign-out' },
        ]

        return (
            <div className="navbar" style={{ backgroundColor: "dodgerblue" }}>
                <div style={{ float: "right", width: "65%" }}>
                    <span style={{ fontSize: "20px", color: "white" }}>Historical<b>Data@PMI</b></span>
                    <Menu pointing secondary floated="right">
                        <Menu.Item as={NavLink} to="/search" name='search' active={this.state.activeItem === 'search'} onClick={this.handleItemClick} />
                    </Menu>
                </div>
                <div style={{ float: "right" }}>
                    <span>
                        Affiliate{' '}
                        <Dropdown inline style={{ marginLeft: "5px" }} options={this.props.affiliateList} value={this.props.affiliateSelected} onChange={this.props.setAffiliate} />
                    </span>
                </div>
                <div style={{ float: "right" }}>
                    <Dropdown simple item direction='right' trigger={userTrigger} value={this.state.userMenuValue} options={userOptions} onChange={this.handleUserAction} style={{ width: "200px" }} />
                </div>
            </div>
        );
    }
}
