/*
Author: Kamakhya Pandey, Radha Goswami
Created:
Last updated: 02-Feb-2021
Purpose: Search Component
Search component is the main component of the Historical Data application. 
If allows the user to perform search for a document on the basis of Category, Folder and Document Type.
User can provide additional filter criteria for search 

Information:
    Categories: Categories based on selected affiliate and user's IMDL role
    Folder: Folders based on selected Category and user's IMDl role
    Document Type: Document Types based on selected Folder
    Document Type Index (under Advanced options): Document Type Indexes based on selected Document Type
    Search Result: Matching rows of search results (Sl, File Id, Filename, Created Date, Owner, List of External Indexes)
    Total results, Total Pages, Current Page

Search filters:    
    Date Range : User can add date range (from, to) criteria
    Advanced options: User can provide multiple Document Type Index filter criteria
    Join Operator: Multiple Document Type Index filter criteria join option; default is OR

Actions:
Pagination: First Page, Previous Page, Next Page, Last Page, Go to Page, Lines Per Page
Download File : Download the file from server to user machine
View File: Open the file in FileList component to list all the file in archive. If the file is not an archive, it will list only itself.
*/
import React, { Component } from 'react';
import { Form, Dropdown, Button, Icon } from 'semantic-ui-react';
import { getTokens, refreshTokens } from './auth';
import FileList from './visualizer/FileList';
import './css/Search.css';
import $ from 'jquery';

let correctaffiliate=false;
let otherheader = [];
let dynamicheader = [];

export default class Search extends Component {
    state = {
        bearerToken: '',
        refreshToken: '',
        tokenExpiry: '',
        operatorValue: "OR",
        categoryList: [],
        categorySelected: 0,
        folderList: [],
        folderSelected: 0,
        documentTypeList: [],
        documentTypeSelected: 0,
        indexTypeList: [],
        documentTypeIndexList: [],
        searchResultList: [],
        searchResultCount: 0,
        filterList: [],
        currentFileId: "",
        archiveFileRelativePath: "",
        currentFileName: "",
        fileList: [],
        fileListCount: 0,
        totalPages: 0,
        currentPageNo: 1,
        currentPageNoSearch: 1,
        currentPageUrl: "",
        dateFrom: '',
        dateTo: '',
        maxDateFrom: '',
        minDateTo: '',
        maxDateTo: '',
        srLimitList: [],
        srLimitSelected: 0,
        srPageList: [],
        srPageSelected: 0,
        srPageTotal: 0,
        srRecordsTotal: 0,
        srRecordsShowingFrom: 0,
        srRecordsShowingTo: 0,
        srLoading: false,
        srLoadingText: '',
        visualizerDisableSearch: false,
        visualizerInnerHTML: '',
        visualizerSearchText: '',
        visualizerSearchMatchCase: false,
        visualizerSearchMatchCount: 0,
        visualizerSearchCurrentMatchPosition: 0,
        
    }

    componentDidMount() {
        const today = new Date();
        const dateTo = this.formatDate(new Date());

        today.setDate(today.getDate() - 3650);
        const dateFrom = this.formatDate(today);

        const srLimitList = [
            { key: 10, value: 10, text: '10' },
            { key: 15, value: 15, text: '15' },
            { key: 20, value: 20, text: '20' },
            { key: 30, value: 30, text: '30' },
            { key: 40, value: 40, text: '40' },
            { key: 50, value: 50, text: '50' },
            { key: 100, value: 100, text: '100' },
            { key: 200, value: 200, text: '200' },
            { key: 500, value: 500, text: '500' },
            { key: 1000, value: 1000, text: '1000' },
        ];

        const srPageList = [{ key: 1, value: 1, text: '1' }];

        this.setState({
            dateFrom: dateFrom,
            maxDateFrom: dateTo,
            dateTo: dateTo,
            minDateTo: dateFrom,
            maxDateTo: dateTo,
            srPageList: srPageList,
            srPageSelected: 1,
            srLimitList: srLimitList,
            srLimitSelected: 15
        }, async () => {
            this.updateTokens();
            this.loadCategory();
        });
    }
    
    bindTableHeader=()=>{
    if(dynamicheader.length===0){
        let totalincomingcolumn=this.state.documentTypeIndexList.length;
        let diffcolumn=9-totalincomingcolumn;
        this.state.documentTypeIndexList.forEach((documentTypeIndex) =>
          dynamicheader.push(<th className="text-center" key={documentTypeIndex.text.toString()}>{documentTypeIndex.text.toString()}</th>)
        )
        for(let j=1;j<=diffcolumn;j++){
            dynamicheader.push(<th className="text-center">{j}</th>)
        }
     }
       return dynamicheader;
    }

    bindheaderother=()=>{
        if(otherheader.length===0){
            for(let i=1;i<10;i++)
            {
                otherheader.push(<th className="text-center">{i}</th>)
            }
        }
        return otherheader;
    }
    updateTokens = () => {
        console.log("Updating token information in state...");

        const tokens = getTokens();

        const bearer = (tokens !== null) ? 'Bearer ' + tokens.idToken : '';
        const refresh = (tokens !== null) ? tokens.refreshToken : '';

        console.log(tokens.tokenExpiry);

        const tokenExpiry = (tokens !== null) ? new Date(tokens.tokenExpiry) : '';

        this.setState({
            bearerToken: bearer,
            refreshToken: refresh,
            tokenExpiry: tokenExpiry
        }, () => {
            console.log("State updated.");

            //console.log("Bearer Token : " + this.state.bearerToken);
            //console.log("Refresh Token : " + this.state.refreshToken);
            //console.log("Token Expiry : " + this.state.tokenExpiry);
        });
    }

    executeRefreshTokens = () => {
        const curDateTime = new Date();

        if (curDateTime > this.state.tokenExpiry) {
            console.log("Token expired. Refreshing tokens...");
            try {
                refreshTokens(this.state.refreshToken);
                this.updateTokens();
            } catch (err) {
                console.log(err);
            }
        } else {
            console.log("Token expiry was set to " + this.state.tokenExpiry + ". Token is still valid. Token refresh not required.");
        }
    }

    handleInvalidResponse = (response, customMsg="") => {
        let errorMsg = '';
        switch (response.status) {
            case 401:
                errorMsg = 'Authentication failed. This may be because token has expired. Please re-login.';
                break;
            case 404:
                errorMsg = 'Resource not found.';
                break;
            case 500:
                errorMsg = 'Internal server error.';
                break;
            default:
                errorMsg = 'Something went wrong.';
                break;
        }
        errorMsg = errorMsg + ((customMsg !== "") ? "\n" + customMsg : "");
        alert(errorMsg);

        return errorMsg;
    }

    loadCategory = () => {
        if (this.props.affiliateSelected > 0) {
            this.clearVisualizationState();
            this.loaddefault();
            this.setState({
                categoryList: [{ key: 0, value: 0, text: 'Select Category' }],
                categorySelected: 0,
                folderList: [{ key: 0, value: 0, text: 'Select Folder' }],
                folderSelected: 0,
                documentTypeList: [{ key: 0, value: 0, text: 'Select Document Type' }],
                documentTypeSelected: 0,
                indexTypeList: [],
                documentTypeIndexList: [],
                searchResultList: [],
                searchResultCount: 0,
                srRecordsTotal: 0,
                srPageSelected: 1,
                srLoading: true,
                srLoadingText: "Loading Categories..."
            }, async () => {
                let apiURL = "/api/Category/GetByRefAffiliate/" + this.props.affiliateSelected.toString();

                console.log(apiURL);

                this.executeRefreshTokens();

                try {
                    const response = await fetch(apiURL, {
                        method: 'GET',
                        headers: { 'Authorization': this.state.bearerToken },
                        responseType: 'json'
                    })

                    if (!response.ok) {
                        const errorMsg = this.handleInvalidResponse(response);
                        throw new Error(errorMsg)
                    }

                    const data = await response.json();

                    let categoryFromAPI = data.map(category => {
                        return { key: category.id, value: category.id, text: category.name }
                    });

                    this.setState({
                        categoryList: [{ key: 0, value: 0, text: 'Select Category' }].concat(categoryFromAPI)
                    });
                } catch (err) {
                    console.log(err);
                }

                this.setState({
                    srLoading: false,
                    srLoadingText: ''
                });
            });
        }
        else {
            alert('No affiliate selected')
        }
    }

    loadFolder = (e, data) => {
        if (data.value > 0) {
            this.clearVisualizationState();
            this.loaddefault();
            this.setState({
                categorySelected: data.value,
                folderList: [{ key: 0, value: 0, text: 'Select Folder' }],
                folderSelected: 0,
                documentTypeList: [{ key: 0, value: 0, text: 'Select Document Type' }],
                documentTypeSelected: 0,
                indexTypeList: [],
                documentTypeIndexList: [],
                searchResultList: [],
                searchResultCount: 0,
                srRecordsTotal: 0,
                srPageSelected: 1,
                srLoading: true,
                srLoadingText: "Loading Folders..."
            }, async () => {
                let apiURL = "/api/Folder/GetByRefCategory/" + data.value.toString();

                console.log(apiURL);

                this.executeRefreshTokens();

                try {
                    const response = await fetch(apiURL, {
                        method: 'GET',
                        headers: { 'Authorization': this.state.bearerToken },
                        responseType: 'json'
                    })

                    if (!response.ok) {
                        const errorMsg = this.handleInvalidResponse(response);
                        throw new Error(errorMsg)
                    }

                    const data = await response.json();

                    let folderFromAPI = data.map(folder => {
                        return { key: folder.id, value: folder.id, text: folder.name }
                    });
                    this.setState({
                        folderList: [{ key: 0, value: 0, text: 'Select Folder' }].concat(folderFromAPI)
                    });
                } catch (err) {
                    console.log(err);
                }

                this.setState({
                    srLoading: false,
                    srLoadingText: ''
                });
            });
        }
    }

    loadDocumentType = (e, data) => {
        if (data.value > 0) {
            this.clearVisualizationState();

            this.loaddefault();

            this.setState({
                folderSelected: data.value,
                documentTypeList: [{ key: 0, value: 0, text: 'Select Document Type' }],
                documentTypeSelected: 0,
                indexTypeList: [],
                documentTypeIndexList: [],
                searchResultList: [],
                searchResultCount: 0,
                srRecordsTotal: 0,
                srPageSelected: 1,
                srLoading: true,
                srLoadingText: "Loading Document Types..."
            }, async () => {
                let apiURL = "/api/DocumentType/GetByRefFolder/" + data.value.toString();

                console.log(apiURL);

                this.executeRefreshTokens();

                try {
                    const response = await fetch(apiURL, {
                        method: 'GET',
                        headers: { 'Authorization': this.state.bearerToken },
                        responseType: 'json'
                    })

                    if (!response.ok) {
                        const errorMsg = this.handleInvalidResponse(response);
                        throw new Error(errorMsg)
                    }

                    const data = await response.json();

                    let documentTypeFromAPI = data.map(documentType => {
                        return {
                            key: documentType.id,
                            value: documentType.id,
                            text: documentType.name
                        }
                    });
                    this.setState({
                        documentTypeList: [{ key: 0, value: 0, text: 'Select Document Type' }].concat(documentTypeFromAPI)
                    }, () => {
                        this.loadIndexType();
                    });
                } catch (err) {
                    console.log(err);
                }

                this.setState({
                    srLoading: false,
                    srLoadingText: ''
                });
            });
        }
    }

    loadIndexType = () => {
        if (this.props.affiliateSelected > 0) {
            this.setState({
                indexTypeList: [],
                srLoading: true,
                srLoadingText: "Loading Index Types..."
            }, async () => {
                let apiURL = "/api/IndexType";

                console.log(apiURL);

                this.executeRefreshTokens();

                try {
                    const response = await fetch(apiURL, {
                        method: 'GET',
                        headers: { 'Authorization': this.state.bearerToken },
                        responseType: 'json'
                    })

                    if (!response.ok) {
                        const errorMsg = this.handleInvalidResponse(response);
                        throw new Error(errorMsg)
                    }

                    const data = await response.json();

                    let indexTypeFromAPI = data.map(indexType => {
                        return { key: indexType.id, value: indexType.id, text: indexType.name, refColumnName: indexType.refColumnName }
                    });
                    this.setState({
                        indexTypeList: [].concat(indexTypeFromAPI)
                    });
                } catch (err) {
                    console.log(err);
                }

                this.setState({
                    srLoading: false,
                    srLoadingText: ''
                });
            });
        }
        else {
            alert('No affiliate selected')
        }
    }

    loadDocumentTypeIndex = (e, data) => {
        if (data.value > 0) {
            this.clearVisualizationState();
            this.loaddefault();
            this.setState({
                documentTypeSelected: data.value,
                searchResultList: [],
                searchResultCount: 0,
                srRecordsTotal: 0,
                srPageSelected: 1,
                srLoading: true,
                srLoadingText: "Loading Document Type Indexes..."
            }, async () => {
                let apiURL = "/api/DocumentTypeIndex/GetByRefDocumentType/" + data.value.toString();

                console.log(apiURL);

                this.executeRefreshTokens();

                try {
                    const response = await fetch(apiURL, {
                        method: 'GET',
                        headers: { 'Authorization': this.state.bearerToken },
                        responseType: 'json'
                    })

                    if (!response.ok) {
                        const errorMsg = this.handleInvalidResponse(response);
                        throw new Error(errorMsg)
                    }

                    const data = await response.json();

                    let documentTypeIndexFromAPI = data.map(documentTypeIndex => {
                        return { key: documentTypeIndex.id, value: documentTypeIndex.id, text: documentTypeIndex.name, indextype: documentTypeIndex.indexTypeId }
                    });
                    this.setState({
                        documentTypeIndexList: [].concat(documentTypeIndexFromAPI)
                    });
                } catch (err) {
                    console.log(err);
                }

                this.setState({
                    srLoading: false,
                    srLoadingText: ''
                });
            });
        }
    }

    handleOperatorChange = (e, { value }) => this.setState({ operatorValue: value })

    loadSearchResults = (showValidationError) => {
        
        let selectedaffiliate=this.props.affiliateSelectedName;
       
        if(selectedaffiliate==="PMEC"){
            correctaffiliate=true;
            this.bindTableHeader();
            otherheader=[];
        }
        else{
            this.loaddefault();
        }
       
        if (this.validateFields(showValidationError) === true) {
            this.clearVisualizationState();

            this.setState({
                searchResultList: [],
                searchResultCount: 0,
                srRecordsTotal: 0,
                srLoading: true,
                srLoadingText: "Searching..."
            }, async () => {
                var filterConditions = [];

                let advancedOptionChecked = document.getElementById("chkAdvancedSearch").checked;

                this.state.indexTypeList.forEach(indexType => {
                    let indexTypeId = indexType.value;
                    let columnName = indexType.refColumnName.toString();

                    let filterConditionItem = {};

                    this.state.documentTypeIndexList.forEach(documentTypeIndex => {
                        if (documentTypeIndex.indextype === indexTypeId) {

                            let indexId = documentTypeIndex.key;
                            let indexName = documentTypeIndex.text.toString();
                            let indexField = document.getElementById("txtIndex_" + indexName);
                            let searchText = (advancedOptionChecked === true) ? ((indexField != null) ? indexField.value : "") : "";

                            if (searchText !== "") {
                                filterConditionItem[indexId] = searchText;
                            }
                        }

                    })

                    let filterCondition = {};

                    filterCondition[columnName] = filterConditionItem;
                    filterConditions.push(filterCondition);
                })

                let apiURL = "/api/Document";
                apiURL = apiURL + "/" + this.state.documentTypeSelected;
                //CTPT-3449 (No results found when Date From and Date To are same)
                apiURL = apiURL + "/" + document.getElementById("dtDateFrom").value + " 00:00:00";
                apiURL = apiURL + "/" + document.getElementById("dtDateTo").value + " 23:59:59";
                apiURL = apiURL + "/" + ((advancedOptionChecked === true) ? this.state.operatorValue.toString() : 'OR');
                apiURL = apiURL + "/" + JSON.stringify(filterConditions);
                apiURL = apiURL + "/" + this.state.srPageSelected.toString();
                apiURL = apiURL + "/" + this.state.srLimitSelected.toString();

                console.log(apiURL);

                this.executeRefreshTokens();

                try {
                    const response = await fetch(apiURL, {
                        method: 'GET',
                        headers: { 'Authorization': this.state.bearerToken },
                        responseType: 'json'
                    })

                    if (!response.ok) {
                        const errorMsg = this.handleInvalidResponse(response);
                        throw new Error(errorMsg)
                    }
                      
                    const data = await response.json();

                    let serialNo = 1; 
                    let PageSelected = this.state.srPageSelected;
                    if (PageSelected > 1) {
                        serialNo = (this.state.srLimitSelected * (PageSelected - 1))+1;
                    }
                                   
                    let searchResultsFromAPI = data.searchResult.map(searchResult => {
                        return {
                            SerialNo: serialNo++,
                            ID: searchResult.id,
                            Code: searchResult.code,
                            CreatedDate: searchResult.createdDate.split('T')[0],
                            Owner: searchResult.owner,
                            FileName: searchResult.filename,
                            ObjectKey: searchResult.objectKey,
                            ExternalIndexValue_1: searchResult.externalIndexValue_1,
                            ExternalIndexValue_2: searchResult.externalIndexValue_2,
                            ExternalIndexValue_3: searchResult.externalIndexValue_3,
                            ExternalIndexValue_4: searchResult.externalIndexValue_4,
                            ExternalIndexValue_5: searchResult.externalIndexValue_5,
                            ExternalIndexValue_6: searchResult.externalIndexValue_6,
                            ExternalIndexValue_7: searchResult.externalIndexValue_7,
                            ExternalIndexValue_8: searchResult.externalIndexValue_8,
                            ExternalIndexValue_9: searchResult.externalIndexValue_9
                        }
                    });

                    var arr = [];
                    for (var i = 1; i <= data.totalPages; i++) {
                        arr.push({ key: i, value: i, text: i.toString() });
                    }

                    this.setState({
                        srPageList: [].concat(arr),
                        srPageSelected: data.pageNumber,
                        srPageTotal: data.totalPages,
                        srRecordsShowingFrom: data.recordsShowingFrom,
                        srRecordsShowingTo: data.recordsShowingTo,
                        srRecordsTotal: data.recordsTotal,
                        searchResultList: [].concat(searchResultsFromAPI),
                        searchResultCount: searchResultsFromAPI.length,
                        filterList: []
                    });
                } catch (err) {
                    console.log(err);
                }

                this.setState({
                    srLoading: false,
                    srLoadingText: ''
                });
            });
        }

        return false;
    }

    downloadFile = (id, archiveFileRelativePath = "",viewType = "") => {
        if (id > 0) {
            console.log("Downloading File Id : " + id.toString());

            let filename = "";

            if (archiveFileRelativePath === "") {
                filename = this.state.searchResultList.find(x => x.ID === id).FileName;
            }
            else {
                filename = this.state.fileList.find(x => x.ArchiveRelativePath === archiveFileRelativePath).FileName;
            }

            this.setState({
                srLoading: true,
                srLoadingText: "Downloading..."
            }, async () => {
                console.log("Downloading Filename : " + filename.toString());

                let apiURL = "/api/Document/DownloadFile/" + id.toString() + "/" + filename.toString() + "/" + ((archiveFileRelativePath !== '') ? (archiveFileRelativePath + "/") : '');

                console.log(apiURL);

                this.executeRefreshTokens();

                try {
                    const response = await fetch(apiURL, {
                        method: 'GET',
                        headers: { 'Authorization': this.state.bearerToken },
                        responseType: 'blob'
                    })

                    if (!response.ok) {
                        const errorMsg = this.handleInvalidResponse(response);
                        throw new Error(errorMsg)
                    }

                    const myBlob = await response.blob();
                    const url = window.URL.createObjectURL(myBlob);
                    
                    console.log(myBlob.type);                    
                    if (myBlob.type === "application/pdf" && viewType === ".PDF") {  
                        console.log(viewType);
                        window.open(url,'_blank');
                    }
                    else {
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', filename);
                        document.body.appendChild(link);
                        link.click();
                        link.parentElement.removeChild(link);
                    }
                } catch (err) {
                    console.log(err);
                }

                this.setState({
                    srLoading: false,
                    srLoadingText: ''
                });
            });
        }
    }

    loadFileList = (id) => {
        if (id > 0) {
            console.log("Listing files for Id : " + id.toString());

            const filename = this.state.searchResultList.find(x => x.ID === id).FileName;

            this.setState({
                currentFileName: filename,
                fileList: [],
                fileListCount: -1,
                srLoading: true,
                srLoadingText: "Loading file list..."
            }, async () => {
                let apiURL = "/api/Document/GetFileList/" + id.toString() + "/" + filename.toString() + "/";

                console.log(apiURL);

                this.executeRefreshTokens();

                try {
                    const response = await fetch(apiURL, {
                        method: 'GET',
                        headers: { 'Authorization': this.state.bearerToken },
                        responseType: 'json'
                    })

                    if (!response.ok) {
                        const errorMsg = this.handleInvalidResponse(response);
                        throw new Error(errorMsg)
                    }

                    const data = await response.json();

                    let fileListFromAPI = data.map(fileListItem => {
                        return {
                            FileId: fileListItem.fileId,
                            FileName: fileListItem.fileName,
                            ParentId: fileListItem.parentId,
                            ParentFileName: fileListItem.parentFileName,
                            IsInArchive: fileListItem.isInArchive,
                            ArchiveRelativePath: fileListItem.archiveRelativePath
                        }
                    });
                    this.setState({
                        currentFileId: id,
                        currentFileName: filename,
                        fileList: [].concat(fileListFromAPI),
                        fileListCount: fileListFromAPI.length
                    });
                } catch (err) {
                    console.log(err);
                }

                this.setState({
                    srLoading: false,
                    srLoadingText: ''
                });
            });
        }
    }

    viewFile = (id, archiveFileRelativePath = "") => {
        if (id > 0) {
            console.log("Getting File Info for Id : " + id.toString());

            let filename = "";

            if (archiveFileRelativePath === "") {                
                filename = this.state.searchResultList.find(x => x.ID === id).FileName;
            }
            else {                
                filename = this.state.fileList.find(x => x.ArchiveRelativePath === archiveFileRelativePath).FileName;
                
            }
            var filExt = filename.substring(filename.length - 4);
            
            if (filExt.toUpperCase() === ".PDF") {
                console.log(filExt);
                this.downloadFile(id, archiveFileRelativePath, filExt.toUpperCase());                
                return;
            } 


            this.setState({
                currentPageUrl: "",
                currentFileId: id,
                archiveFileRelativePath: archiveFileRelativePath,
                srLoading: true,
                srLoadingText: "Opening file..."
            }, async () => {
                let apiURL = "/api/Document/GetFileInfo/" + id.toString() + "/" + filename.toString() + "/" + this.state.documentTypeSelected.toString() + "/" + ((archiveFileRelativePath !== '') ? (archiveFileRelativePath + "/") : '');;

                console.log(apiURL);

                this.executeRefreshTokens();

                try {
                    const response = await fetch(apiURL, {
                        method: 'GET',
                        headers: { 'Authorization': this.state.bearerToken },
                        responseType: 'json'
                    })
                    console.log("response status :");
                    console.log(response.status);
                    if (!response.ok) {
                        const customMsg = "This file does not support visualization. Please download the file.";
                        const errorMsg = this.handleInvalidResponse(response, customMsg);
                        throw new Error(errorMsg);
                    }

                    const data = await response.json();
                    //console.log(data);
                    this.setState({
                        currentPageNoSearch: 1,
                        totalPages: data.totalPages,
                        visualizerDisableSearch: ((data.isSearchable === true) ? false : true)
                    }, () => {
                        //visualizerDisableSearch: ((data.isSearchable === true)? false : true)
                        console.log("Total pages : " + this.state.totalPages);
                        console.log("Searchable : " + this.state.visualizerDisableSearch);
                        if (this.state.totalPages > 0) {
                            this.getFilePage();
                        }
                        else {
                            alert('This file type does not support visualization. Please download the file.')
                        }
                    });
                } catch (err) {
                    console.log(err);
                }

                this.setState({
                    srLoading: false,
                    srLoadingText: ''
                });
            });
        }
    }

    getFilePage = () => {
        if (this.state.currentFileId > 0 && this.state.currentPageNoSearch > 0) {
            this.clearVisualizationState();
            console.log("Loading page : " + this.state.currentPageNoSearch.toString());

            let filename = "";

            if (this.state.archiveFileRelativePath === "") {
                filename = this.state.searchResultList.find(x => x.ID === this.state.currentFileId).FileName;
            }
            else {
                filename = this.state.fileList.find(x => x.ArchiveRelativePath === this.state.archiveFileRelativePath).FileName;
            }

            this.setState({
                srLoading: true,
                srLoadingText: "Loading page..."
            }, async () => {
                let apiURL = "/api/Document/GetFilePage/" + this.state.currentFileId.toString() + "/" + filename.toString() + "/" + this.state.documentTypeSelected.toString() + "/" +
                    this.state.currentPageNoSearch.toString() + "/" + ((this.state.archiveFileRelativePath !== '') ? (this.state.archiveFileRelativePath + "/") : '');

                console.log(apiURL);

                this.executeRefreshTokens();

                try {
                    const response = await fetch(apiURL, {
                        method: 'GET',
                        headers: { 'Authorization': this.state.bearerToken },
                        responseType: 'blob'
                    })

                    if (!response.ok) {
                        const customMsg = "This file does not support visualization. Please download the file.";
                        const errorMsg = this.handleInvalidResponse(response, customMsg);
                        throw new Error(errorMsg)
                    }

                    const myBlob = await response.blob();

                    let url = window.URL.createObjectURL(myBlob);
                    url = url + '#toolbar=0';
                    console.log(url);
                    this.setState(prevState => ({
                        currentPageUrl: url,
                        currentPageNo: prevState.currentPageNoSearch
                    }), () => {
                        $('#modalVisualizer').modal('show');
                    });
                } catch (err) {
                    console.log(err);
                }

                this.setState({
                    srLoading: false,
                    srLoadingText: ''
                });
            });
        }
    }

    downloadFilePage = () => {
        if (this.state.currentFileId > 0 && this.state.currentPageNoSearch > 0) {
            console.log("Downloading page : " + this.state.currentPageNoSearch.toString());

            let filename = "";

            if (this.state.archiveFileRelativePath === "") {
                filename = this.state.searchResultList.find(x => x.ID === this.state.currentFileId).FileName;
            }
            else {
                filename = this.state.fileList.find(x => x.ArchiveRelativePath === this.state.archiveFileRelativePath).FileName;
            }

            this.setState({
                srLoading: true,
                srLoadingText: "Downloading page..."
            }, async () => {
                let apiURL = "/api/Document/DownloadFilePage/" + this.state.currentFileId.toString() + "/" + filename.toString() + "/" + this.state.documentTypeSelected.toString() + "/" +
                    this.state.currentPageNoSearch.toString() + "/" + ((this.state.archiveFileRelativePath !== '') ? (this.state.archiveFileRelativePath + "/") : '');

                console.log(apiURL);

                this.executeRefreshTokens();

                try {
                    const response = await fetch(apiURL, {
                        method: 'GET',
                        headers: { 'Authorization': this.state.bearerToken },
                        responseType: 'blob'
                    })

                    if (!response.ok) {
                        const errorMsg = this.handleInvalidResponse(response);
                        throw new Error(errorMsg)
                    }

                    const myBlob = await response.blob();

                    const url = window.URL.createObjectURL(myBlob);
                    const link = document.createElement('a');
                    link.href = url;

                    let newFilename = filename.replace('.', '_') + '_' + this.state.currentPageNoSearch.toString() + '.pdf';

                    link.setAttribute('download', newFilename);
                    document.body.appendChild(link);
                    link.click();
                    link.parentElement.removeChild(link);
                } catch (err) {
                    console.log(err);
                }

                this.setState({
                    srLoading: false,
                    srLoadingText: ''
                });
            });
        }
    }

    firstPage = () => {
        if (this.state.currentPageNo > 1) {
            this.setState({
                currentPageNoSearch: 1
            }, () => {
                this.getFilePage();
            });
        }
    }

    prevPage = () => {
        if (this.state.currentPageNo > 1) {
            this.setState(prevState => ({
                currentPageNoSearch: ((prevState.currentPageNo > 1) ? (parseInt(prevState.currentPageNo) - 1) : 1)
            }), () => {
                this.getFilePage();
            });
        }
    }

    nextPage = () => {
        if (this.state.currentPageNo < this.state.totalPages) {
            this.setState(prevState => ({
                currentPageNoSearch: ((prevState.currentPageNo < prevState.totalPages) ? (parseInt(prevState.currentPageNo) + 1) : prevState.totalPages)
            }), () => {
                this.getFilePage();
            });
        }
        return false;
    }

    lastPage = () => {
        if (this.state.currentPageNo < this.state.totalPages) {
            this.setState(prevState => ({
                currentPageNoSearch: prevState.totalPages
            }), () => {
                this.getFilePage();
            });
        }
    }

    goToPage = (page) => {
        if (page >= 1 && page <= this.state.totalPages) {
            this.setState({
                currentPageNoSearch: page
            }, () => {
                this.getFilePage();
            });
        }
        else {
            alert("Go to page must be a valid page number")
        }
    }

    formatDate = (d) => {
        var month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;

        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    setDateRangeFrom = () => {
        const selectedDateFrom = new Date(document.getElementById('dtDateFrom').value);
        const strSelectedDateFrom = this.formatDate(selectedDateFrom);

        this.setState({
            maxDateFrom: strSelectedDateFrom,
            minDateTo: strSelectedDateFrom
        })
    }

    validateFields = (showValidationError) => {
        let isValid = true;

        const selectedDateFrom = new Date(document.getElementById('dtDateFrom').value);
        const selectedDateTo = new Date(document.getElementById('dtDateTo').value);

        if (this.state.categorySelected <= 0) {
            isValid = false;
            if (showValidationError === true)
                alert('Category cannot be empty')
        }
        else if (this.state.folderSelected <= 0) {
            isValid = false;
            if (showValidationError === true)
                alert('Folder cannot be empty')
        }
        else if (this.state.documentTypeSelected <= 0) {
            isValid = false;
            if (showValidationError === true)
                alert('Document Type cannot be empty')
        }

        if (selectedDateTo < selectedDateFrom) {
            isValid = false;
            if (showValidationError === true)
                alert('[To Date] must be greater than or equal to [From Date]')
        }

        return isValid;
    }

    handleSRLimit = (e, data) => {
        if (data.value > 0) {
            this.setState({
                srLimitSelected: data.value,
                srPageSelected: 1,
            }, () => {
                if (this.state.srRecordsTotal > 0) {
                    this.loadSearchResults(false);
                }
            });
        }
    }

    handleSRGoToPage = (e, data) => {
        if (this.state.srRecordsTotal > 0 && data.value > 0) {
            this.setState({
                srPageSelected: data.value
            }, () => {
                if (this.state.srRecordsTotal > 0) {
                    this.loadSearchResults(false);
                }
            });
        }
    }

    srFirstPage = () => {
        if (this.state.srRecordsTotal > 0 && this.state.srPageSelected > 1) {
            this.setState({
                srPageSelected: 1
            }, () => {
                this.loadSearchResults(false);
            });
        }
    }

    srPrevPage = () => {
        if (this.state.srRecordsTotal > 0 && this.state.srPageSelected > 1) {
            this.setState(prevState => ({
                srPageSelected: ((prevState.srPageSelected > 1) ? (prevState.srPageSelected - 1) : 1)
            }), () => {
                this.loadSearchResults(false);
            });
        }
    }

    srNextPage = () => {
        if (this.state.srRecordsTotal > 0 && this.state.srPageSelected < this.state.srPageTotal) {
            this.setState(prevState => ({
                srPageSelected: ((prevState.srPageSelected < prevState.srPageTotal) ? (prevState.srPageSelected + 1) : prevState.srPageTotal)
            }), () => {
                this.loadSearchResults(false);
            });
        }
        return false;
    }

    srLastPage = () => {
        if (this.state.srRecordsTotal > 0 && this.state.srPageSelected < this.state.srPageTotal) {
            this.setState(prevState => ({
                srPageSelected: prevState.srPageTotal
            }), () => {
                this.loadSearchResults(false);
            });
        }
    }

    clearVisualizationState = () => {
        this.setState(prevState => ({
            visualizerDisableSearch: prevState.visualizerDisableSearch,
            visualizerInnerHTML: '',
            visualizerSearchText: '',
            visualizerSearchMatchCase: false,
            visualizerSearchMatchCount: 0,
            visualizerSearchCurrentMatchPosition: 0
        }));
    }

    visualizerSearch = async (iframeInnerHTML) => {
        var searchText = this.state.visualizerSearchText
        console.log("Searching for " + searchText);
        return new Promise(resolve => {
            this.setState(prevState => ({
                visualizerInnerHTML: ((prevState.visualizerInnerHTML === '') ? iframeInnerHTML : prevState.visualizerInnerHTML)
            }), async () => {
                var searchRegex = ((this.state.visualizerSearchMatchCase === true) ? new RegExp(this.state.visualizerSearchText, 'g') : new RegExp(this.state.visualizerSearchText, 'ig'));
                var matchCount = (this.state.visualizerInnerHTML.match(searchRegex) || []).length;

                if (this.state.visualizerSearchText === '') {
                    matchCount = 0;
                }

                this.setState({
                    visualizerSearchMatchCount: matchCount,
                    visualizerSearchCurrentMatchPosition: 0
                }, async () => {
                    console.log("Matches : " + this.state.visualizerSearchMatchCount + " | Current Position : " + this.state.visualizerSearchCurrentMatchPosition);

                    var returnString = await this.visualizerHighlightMatches();
                    resolve(returnString);
                });
            });
        });
    }

    visualizerHighlightMatches = async () => {
        var searchRegex = ((this.state.visualizerSearchMatchCase === true) ? new RegExp(this.state.visualizerSearchText, 'g') : new RegExp(this.state.visualizerSearchText, 'ig'));
        var mainString = this.state.visualizerInnerHTML;
        var resultString = "";
        var iMatchCount = 0;

        if (this.state.visualizerSearchText !== '') {
            while (true) {
                var pos = mainString.search(searchRegex);

                if (pos >= 0) {
                    iMatchCount++;

                    resultString = resultString + mainString.substring(0, pos);

                    var foundString = mainString.substring(pos, pos + this.state.visualizerSearchText.length);
                    var replaceString = "";

                    if (iMatchCount === this.state.visualizerSearchCurrentMatchPosition) {
                        replaceString = '<span style="background-color: orange;">' + foundString + "</span>";
                    } else {
                        replaceString = '<span style="background-color: yellow;">' + foundString + "</span>";
                    }

                    resultString = resultString + replaceString;

                    mainString = mainString.substring(pos + this.state.visualizerSearchText.length);
                } else {
                    break;
                }
            }
        }

        resultString = resultString + mainString;

        return resultString;
    }

    handleVisualizerSearchTextChange = (e) => {
        this.setState({
            visualizerSearchText: e.target.value
        }, () => {
            console.log("Search text : " + this.state.visualizerSearchText);
        });
    }

    setVisualizerSearchMatchCase = (e) => {
        this.setState({
            visualizerSearchMatchCase: e.target.checked
        });
    }

    incrementVisualizerMatchPosition = async () => {
        if (this.state.visualizerSearchMatchCount > 0) {
            return new Promise(resolve => {
                this.setState(prevState => ({
                    visualizerSearchCurrentMatchPosition: ((prevState.visualizerSearchCurrentMatchPosition < prevState.visualizerSearchMatchCount) ? (prevState.visualizerSearchCurrentMatchPosition + 1) : 1)
                }), async () => {
                    console.log("Current Position : " + this.state.visualizerSearchCurrentMatchPosition);

                    var returnString = this.visualizerHighlightMatches();

                    resolve(returnString);
                });
            });
        } else {
            return this.state.visualizerInnerHTML;
        }
    }

    decrementVisualizerMatchPosition = async () => {
        if (this.state.visualizerSearchMatchCount > 0) {
            return new Promise(resolve => {
                this.setState(prevState => ({
                    visualizerSearchCurrentMatchPosition: ((prevState.visualizerSearchCurrentMatchPosition > 1) ? (prevState.visualizerSearchCurrentMatchPosition - 1) : prevState.visualizerSearchMatchCount)
                }), () => {
                    console.log("Current Position : " + this.state.visualizerSearchCurrentMatchPosition);

                    var returnString = this.visualizerHighlightMatches();

                    resolve(returnString);
                });
            });
        } else {
            return this.state.visualizerInnerHTML;
        }
    }

    loaddefault() {
        correctaffiliate = false;
        this.bindheaderother();
        dynamicheader = [];
    }

    render() {
        
        let conditionSection = '';
        if (this.state.documentTypeIndexList.length > 0) {
            conditionSection = <Form.Group className="" inline style={{}}><label className="" style={{ width: "100px", fontWeight: "normal" }}> Combine by</label ><Form.Radio key="radioAND" id="OperatorAND" label="AND" value="AND" checked={this.state.operatorValue === "AND"} onChange={this.handleOperatorChange} disabled={this.state.srLoading} /><Form.Radio key="radioOR" id="OperatorOR" label="OR" value="OR" checked={this.state.operatorValue === "OR"} onChange={this.handleOperatorChange} disabled={this.state.srLoading} /></Form.Group >
              
        }
        const today = new Date();
        const dateTo = this.formatDate(new Date());

        today.setDate(today.getDate() - 7300);
        const dateFrom = this.formatDate(today);

        let searchResultText = '';

        if (this.state.srRecordsTotal > 0) {
            searchResultText = <span>{"Showing " + this.state.srRecordsShowingFrom + " to " + this.state.srRecordsShowingTo + " of " + this.state.srRecordsTotal}</span>
        }
        else {
            searchResultText = <span>Showing 0 results</span>
        }

        let busyMsgText = '';
        if (this.state.srLoading === true) {
            busyMsgText = <span><i className="fa fa-circle-o-notch fa-spin"></i> {this.state.srLoadingText + " please wait"}</span>
        }


        return (
            <div>
                <div className="searchBaseContainer">
                    <div className="searchControlContainer">
                        <Form className="" size='tiny'>
                            <div className="grpSearchMainControl">
                                <Form.Field inline required>
                                    <label htmlFor="cmbCategory" className="searchFormControlLabel">Category</label>
                                    <Dropdown search selection id="cmbCategory" className="searchFormControlDropDown" placeholder='Select Category' options={this.state.categoryList} value={this.state.categorySelected} onChange={this.loadFolder} disabled={this.state.srLoading} />
                                </Form.Field>
                                <Form.Field inline required>
                                    <label htmlFor="cmbFolder" className="searchFormControlLabel">Folder</label>
                                    <Dropdown search selection id="cmbFolder" className="searchFormControlDropDown" placeholder='Select Folder' options={this.state.folderList} value={this.state.folderSelected} onChange={this.loadDocumentType} disabled={this.state.srLoading} />
                                </Form.Field>
                                <Form.Field inline required>
                                    <label htmlFor="cmbDocumentType" className="searchFormControlLabel">Doc. Type</label>
                                    <Dropdown search selection id="cmbDocumentType" className="searchFormControlDropDown" placeholder='Select Document Type' options={this.state.documentTypeList} value={this.state.documentTypeSelected} onChange={this.loadDocumentTypeIndex} disabled={this.state.srLoading} />
                                </Form.Field>
                                <Form.Field inline>
                                    <label htmlFor="dtDateFrom" className="searchFormControlLabel">Date From</label>
                                    <input id="dtDateFrom" type="date" className="searchFormControlDate" defaultValue={dateFrom} max={this.state.maxDateFrom} onBlur={this.setDateRangeFrom} disabled={this.state.srLoading} />
                                </Form.Field>
                                <Form.Field inline>
                                    <label htmlFor="dtDateTo" className="searchFormControlLabel">Date To</label>
                                    <input id="dtDateTo" type="date" className="searchFormControlDate" defaultValue={dateTo} min={this.state.minDateTo} max={this.state.maxDateTo} disabled={this.state.srLoading} />
                                </Form.Field>
                                <Form.Field inline>
                                    <Form.Checkbox id="chkAdvancedSearch" className="searchFormControlAdvancedCheckBox" data-toggle="collapse" data-target="#grpSearchAdvancedOptions" label='Advanced options' disabled={this.state.srLoading} />
                                </Form.Field>
                                <Form.Button className="searchFormControlButton" primary onClick={(e) => this.loadSearchResults(true)} disabled={this.state.srLoading}>Search</Form.Button>
                            </div>
                            <div id="grpSearchAdvancedOptions" className="collapse grpSearchAdvancedOptions">
                                <div className="searchAdvancedContainer">
                                    {conditionSection}
                                    {this.state.documentTypeIndexList.map((documentTypeIndex) =>
                                        <Form.Field key={"ff_" + documentTypeIndex.key.toString()} >
                                            <label key={"lblIndex_" + documentTypeIndex.key.toString()} htmlFor={"txtIndex_" + documentTypeIndex.text.toString()} className="advancedSearchFormControlLabel">
                                                {"[" + (this.state.indexTypeList.find(x => x.value === documentTypeIndex.indextype).text + "] " + documentTypeIndex.text.toString()).substring(0, 40)}
                                            </label>
                                            <input key={"txtIndex_" + documentTypeIndex.key.toString()} id={"txtIndex_" + documentTypeIndex.text.toString()} type="text"
                                                className="advancedSearchFormControlTextBox" defaultValue="" index-type={documentTypeIndex.indextype.toString()} title={'Index Name : ' + documentTypeIndex.text.toString()} disabled={this.state.srLoading} />
                                        </Form.Field>)
                                    }
                                </div>
                            </div>
                        </Form>
                    </div>
                    <div className="searchResultContainer p-2">
                        <div>
                            <table className="searchResultTable table table-striped" border='1' cellPadding="1" cellSpacing="0">
                                <colgroup>
                                    <col span="1" style={{}} />
                                    <col span="1" style={{}} />
                                    <col span="1" style={{}} />
                                    <col span="1" style={{}} />
                                    <col span="1" style={{}} />
                                    <col span="1" style={{}} />
                                    <col span="1" style={{}} />
                                    <col span="1" style={{}} />
                                    <col span="1" style={{}} />
                                    <col span="1" style={{}} />
                                    <col span="1" style={{}} />
                                    <col span="1" style={{}} />
                                    <col span="1" style={{}} />
                                    <col span="1" style={{}} />
                                    <col span="1" style={{ width: "90px" }} />
                                </colgroup>
                                <thead style={{ backgroundColor: "#666666", fontWeight: "bold", color: "#ffffff", borderColor: "#ffffff" }}>
                                    <tr>
                                        <th className="text-center">#</th>
                                        <th className="text-center">Code</th>
                                        <th className="text-center">Created Date</th>
                                        <th >Owner</th>
                                        <th >FileName</th>
                                        {correctaffiliate?(
                                                this.bindTableHeader()                                    
                                         ):(
                                                 this.bindheaderother()
                                          )} 
                                    <th className="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.searchResultList.map((searchResult) =>
                                        <tr key={"trSearchResult_" + searchResult.SerialNo.toString()}>
                                            <td className="text-center">{searchResult.SerialNo != null ? searchResult.SerialNo.toString().trim() : ''}</td>
                                            <td className="text-center">{searchResult.Code != null ? searchResult.Code.trim() : ''}</td>
                                            <td className="text-center">{searchResult.CreatedDate != null ? searchResult.CreatedDate.toString().trim() : ''}</td>
                                            <td>{searchResult.Owner != null ? searchResult.Owner.trim() : ''}</td>
                                            <td>{searchResult.FileName != null ? searchResult.FileName.trim() : ''}</td>
                                            <td>{searchResult.ExternalIndexValue_1 != null ? searchResult.ExternalIndexValue_1.trim() : ''}</td>
                                            <td>{searchResult.ExternalIndexValue_2 != null ? searchResult.ExternalIndexValue_2.trim() : ''}</td>
                                            <td>{searchResult.ExternalIndexValue_3 != null ? searchResult.ExternalIndexValue_3.trim() : ''}</td>
                                            <td>{searchResult.ExternalIndexValue_4 != null ? searchResult.ExternalIndexValue_4.trim() : ''}</td>
                                            <td>{searchResult.ExternalIndexValue_5 != null ? searchResult.ExternalIndexValue_5.trim() : ''}</td>
                                            <td>{searchResult.ExternalIndexValue_6 != null ? searchResult.ExternalIndexValue_6.trim() : ''}</td>
                                            <td>{searchResult.ExternalIndexValue_7 != null ? searchResult.ExternalIndexValue_7.trim() : ''}</td>
                                            <td>{searchResult.ExternalIndexValue_8 != null ? searchResult.ExternalIndexValue_8.trim() : ''}</td>
                                            <td>{searchResult.ExternalIndexValue_9 != null ? searchResult.ExternalIndexValue_9.trim() : ''}</td>
                                            <td className="text-center">
                                                <Button icon compact color="teal" size="mini" title="Download" onClick={() => this.downloadFile(searchResult.ID)} disabled={this.state.srLoading}><Icon name='download' /></Button>
                                                <Button icon compact color="teal" size="mini" title="View" onClick={() => this.loadFileList(searchResult.ID)} data-toggle="modal" data-target="#modalFileList" disabled={this.state.srLoading} ><Icon name='eye' /></Button>
                                                <div className="modal fade" id="modalFileList" data-backdrop="static" data-keyboard="false" tabIndex="-1" aria-labelledby="modalVisualizerLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-dialog-centered">
                                                        <div className="modal-content">
                                                            <div className="modal-body">
                                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                    <span aria-hidden="true" className="text-danger">&times;</span>
                                                                </button>
                                                                <FileList fileList={this.state.fileList} fileListCount={this.state.fileListCount} currentFileName={this.state.currentFileName}
                                                                    downloadFile={this.downloadFile} viewFile={this.viewFile} srLoading={this.state.srLoading}
                                                                    currentPageNo={this.state.currentPageNo} totalPages={this.state.totalPages} currentPageUrl={this.state.currentPageUrl}
                                                                    firstPage={this.firstPage} prevPage={this.prevPage} nextPage={this.nextPage}
                                                                    lastPage={this.lastPage} goToPage={this.goToPage} downloadFilePage={this.downloadFilePage}
                                                                    visualizerSearchText={this.state.visualizerSearchText} visualizerSearchMatchCase={this.state.visualizerSearchMatchCase}
                                                                    visualizerSearchMatchCount={this.state.visualizerSearchMatchCount} visualizerSearchCurrentMatchPosition={this.state.visualizerSearchCurrentMatchPosition}
                                                                    handleVisualizerSearchTextChange={this.handleVisualizerSearchTextChange} setVisualizerSearchMatchCase={this.setVisualizerSearchMatchCase}
                                                                    setVisualizerSearchMatchCount={this.setVisualizerSearchMatchCount} incrementVisualizerMatchPosition={this.incrementVisualizerMatchPosition}
                                                                    decrementVisualizerMatchPosition={this.decrementVisualizerMatchPosition} visualizerSearch={this.visualizerSearch}
                                                                    visualizerDisableSearch={this.state.visualizerDisableSearch}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            <div className="search-tool-bar">
                                <div id="pageInfoLimit" className="menuSection">
                                    <label>Records per page</label>
                                    <Dropdown compact selection id="cmbSRLimit" className="dropDownField" style={{}} placeholder=' ' options={this.state.srLimitList} value={this.state.srLimitSelected} onChange={this.handleSRLimit} disabled={this.state.srLoading} />
                                </div>
                                <div id="goToPage" className="menuSection">
                                    <label>Page</label>
                                    <Dropdown compact selection id="cmbSRGoTo" className="dropDownField" style={{}} placeholder=' ' options={this.state.srPageList} value={this.state.srPageSelected} onChange={this.handleSRGoToPage} disabled={this.state.srLoading} />
                                </div>
                                <div id="srPaginationButtons" className="menuSection">
                                    <button onClick={this.srFirstPage} disabled={this.state.srLoading} ><i className="iconButton  fa fa-step-backward"></i></button>
                                    <button onClick={this.srPrevPage} disabled={this.state.srLoading} ><i className="iconButton fa fa-chevron-left"></i></button>
                                    <button onClick={this.srNextPage} disabled={this.state.srLoading} ><i className="iconButton fa fa-chevron-right"></i></button>
                                    <button onClick={this.srLastPage} disabled={this.state.srLoading} ><i className="iconButton fa fa-step-forward"></i></button>
                                </div>
                                <div id="pageInfo resultCount" className="menuSection">
                                    {searchResultText}
                                </div>
                                <div id="pageInfo busyMsg" className="menuSection">
                                    {busyMsgText}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
