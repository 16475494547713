/*
Author: Kamakhya Pandey
Created: 
Last updated: 02-Feb-2021
Purpose: FileList component
This component is used to list all the files within the archive. Download and View actions can be performed on 
each of the files listed. 
Download: File will be downloaded to from server to user machine
View: File will be opened in Visualizer for viewing the contents. Not all file types are supported for viewing.
*/

import React, { Component } from 'react';
import { Button, Icon } from 'semantic-ui-react'
import Visualizer from './Visualizer';
import './css/font-awesome.css';
import './css/FileList.css';

export default class FileList extends Component {
    state = {
        Title: "File List"
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="file-list">
                <div className="p-2" style={{}}>
                    <div style={{}}>
                        <h6 className="text-left">File List for "{this.props.currentFileName}"</h6>
                        <table className="table table-striped" border='1' style={{ width: "100%", fontSize: "12px", fontWeight: "normal" }}>
                            <colgroup>
                                <col span="1" style={{ width: "60px" }} />
                                <col span="1" style={{}} />
                                <col span="1" style={{ width: "90px" }} />
                            </colgroup>
                            <thead style={{ backgroundColor: "#666666", fontWeight: "bold", color: "#ffffff", borderColor: "#ffffff" }}>
                                <tr>
                                    <th className="text-center">File Id</th>
                                    <th className="text-left">FileName</th>
                                    <th className="text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan="3" className="text-left">
                                        {(this.props.fileListCount === -1) ? <div className=""><div className="spinner-border" role="status"></div></div> : this.props.fileListCount + ' file(s)'}
                                    </td>
                                </tr>
                                {this.props.fileList.map((listItem) =>
                                    <tr key={"trFileList_" + listItem.FileId }>
                                        <td className="text-center">{listItem.FileId}</td>
                                        <td className="text-left">{listItem.FileName}</td>
                                        <td className="text-center">
                                            <Button icon color="teal" size="mini" title="Download"
                                                onClick={() => this.props.downloadFile(listItem.ParentId, ((listItem.IsInArchive === true) ? listItem.ArchiveRelativePath : ""))} disabled={this.props.srLoading} >
                                                <Icon name='download' />
                                            </Button>
                                            <Button icon color="teal" size="mini" title="View" onClick={() => this.props.viewFile(listItem.ParentId, ((listItem.IsInArchive === true) ? listItem.ArchiveRelativePath : ""))} disabled={this.props.srLoading} ><Icon name='eye' /></Button>
                                            <div className="modal fade" id="modalVisualizer">
                                                <div className="modal-dialog modal-xl modal-dialog-centered">
                                                    <div className="modal-content">
                                                        <div className="modal-body">
                                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                <span aria-hidden="true" className="text-danger">&times;</span>
                                                            </button>
                                                            <Visualizer currentPageNo={this.props.currentPageNo} totalPages={this.props.totalPages} currentPageUrl={this.props.currentPageUrl}
                                                                firstPage={this.props.firstPage} prevPage={this.props.prevPage}
                                                                nextPage={this.props.nextPage} lastPage={this.props.lastPage} goToPage={this.props.goToPage}
                                                                downloadFilePage={this.props.downloadFilePage} srLoading={this.props.srLoading}
                                                                visualizerSearchText={this.props.visualizerSearchText} visualizerSearchMatchCase={this.props.visualizerSearchMatchCase}
                                                                visualizerSearchMatchCount={this.props.visualizerSearchMatchCount} visualizerSearchCurrentMatchPosition={this.props.visualizerSearchCurrentMatchPosition}
                                                                handleVisualizerSearchTextChange={this.props.handleVisualizerSearchTextChange} setVisualizerSearchMatchCase={this.props.setVisualizerSearchMatchCase}
                                                                setVisualizerSearchMatchCount={this.props.setVisualizerSearchMatchCount} visualizerSearch={this.props.visualizerSearch}
                                                                incrementVisualizerMatchPosition={this.props.incrementVisualizerMatchPosition} decrementVisualizerMatchPosition={this.props.decrementVisualizerMatchPosition} 
                                                                visualizerDisableSearch={this.props.visualizerDisableSearch}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}
/*

*/