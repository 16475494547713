import React, { Component } from 'react';
import { getTokens, login, loginUrl, logout, logoutUrl, refreshTokens } from './components/auth';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import NavMenu from './components/NavMenu';
import Search from './components/Search';

import './custom.css'

export default class App extends Component {
    state = {
        code: '',
        bearerToken: '',
        refreshToken: '',
        tokenExpiry: '',
        authenticated: false,
        authenticating: false,
        affiliateLoaded: false,
        affiliateList: [],
        affiliateSelected: 0,
        affiliateSelectedName:''
    }

    setAffiliate = (e, { value }) => {
        console.log("Changing affiliate to : " + value.toString())
        console.log("Changing affiliate to : " + e.target.innerText)
        this.setState({
            affiliateSelected: value,
            affiliateSelectedName:e.target.innerText
        });
    }

    updateTokens = async () => {
        const params = (new URL(document.location)).searchParams;
        const code = params.get('code');
        const tokens = getTokens();
        const bearer = (tokens !== null) ? 'Bearer ' + tokens.idToken : '';
        const refresh = (tokens !== null) ? tokens.refreshToken : '';

        this.setState({
            code: code,
            bearerToken: bearer,
            refreshToken: refresh
        });
    }

    componentDidMount() {
        this.updateTokens().then(() => {
            this.setState(prevState => ({
                authenticated: (prevState.bearerToken !== "") ? true : false,
                authenticating: (prevState.code !== null) ? true : false
            }), () => {

                console.log("Application loading...")
                console.log("Code : " + this.state.code)
                console.log("Bearer Token : " + this.state.bearerToken)
                console.log("Authenticated : " + this.state.authenticated)
                console.log("Authenticating : " + this.state.authenticating)

                if (this.state.code !== null) {
                    this.executeLogin().then(() => {
                        console.log("After signing in...");
                        console.log("Authenticated : " + this.state.authenticated)
                        console.log("Authenticating : " + this.state.authenticating)

                        if (this.state.authenticated === true) {
                            console.log("Loading affiliate after login...")
                            this.updateTokens().then(() => {
                                this.setState({
                                    affiliateLoaded: false
                                }, () => {
                                    this.loadAffiliate();
                                });
                            });
                        }
                        else {
                            console.log("Authenticated flag is still false after login. Can't load affiliate.")
                        }
                    });
                }
                else if (this.state.authenticated === true) {
                    console.log("Loading affiliate...")
                    this.updateTokens().then(() => {
                        this.loadAffiliate();
                    });
                }
            });
        });
    }

    executeLogin = async () => {
        console.log("Signing in...")

        window.history.replaceState({}, document.title, '/');
        try {
            await login(this.state.code).then(() => {
                const expirtyDateTime = new Date();
                expirtyDateTime.setMinutes(expirtyDateTime.getMinutes() + 10);

                this.setState({
                    tokenExpiry: expirtyDateTime,
                    authenticated: true
                });
            });
        } catch (err) {
            console.log(err);
        }
        this.setState({ authenticating: false });
    };

    executeRefreshToken = async () => {
        const curDateTime = new Date();

        if (curDateTime > this.state.tokenExpiry) {
            console.log('Token expiry was set to ' + this.state.tokenExpiry.toString() + '. Refreshing token...');

            try {
                await refreshTokens(this.state.refreshToken).then(() => {
                    this.updateTokens().then(() => {
                        const expirtyDateTime = new Date();
                        expirtyDateTime.setMinutes(expirtyDateTime.getMinutes() + 10);

                        this.setState({
                            tokenExpiry: expirtyDateTime,
                        }, () => {
                            console.log('New token expiry set to ' + this.state.tokenExpiry.toString());
                        });
                    });
                });
            } catch (err) {
                console.log(err);
            }
        }
        else {
            console.log('Token expiry was set to ' + this.state.tokenExpiry.toString() + '. Token still valid, Token refresh not required.');
        }
    }

    executeLogout = () => {
        this.setState({
            authenticated: false,
            authenticating: false,
            affiliateLoaded: false,
            affiliateList: [],
            affiliateSelected: 0,
        }, () => {
            logout();
            window.location.assign(logoutUrl);
        });
    }

    callAPI = async (apiURL, methodType, responseType) => {
        console.log(methodType + " | " + responseType + " | " + apiURL);

        const responseData1 = this.executeRefreshToken().then(() => {
            const responseData2 = fetch(apiURL, {
                method: methodType,
                headers: { 'Authorization': this.state.bearerToken },
                responseType: responseType
            }).then((response) => {
                console.log(response);

                if (response.ok) {
                    if (responseType === "json") {
                        return response.json();
                    }
                    else if (responseType === "blob") {
                        return response.blob();
                    }
                }
                else {
                    switch (response.status) {
                        case 401:
                            alert('Authentication failed. This may be because token has expired. Please re-login.')
                            this.executeLogout();
                            break;
                        case 404:
                            alert('Resource not found.');
                            break;
                        case 500:
                            alert('Server errorr.\n\nError message :\n' + response.text());
                            break;
                        default:
                            alert('Something went wrong.\n\nError message :\n' + response.text());
                            break;
                    }
                    return false;
                }
            }).then((responseData3) => {
                return responseData3;
            }).catch(error => {
                console.log(error);
            });

            return responseData2
        });

        return responseData1
    }

    loadAffiliate = () => {
        let apiURL = "/api/Affiliate";

        this.callAPI(apiURL, 'GET', 'json').then(data => {
            console.log(data);

            let affiliateFromAPI = data.map(affiliate => {
                return { key: affiliate.id, value: affiliate.id, text: affiliate.name }
            });
            console.log("Affiliate count : " + affiliateFromAPI.length.toString())
            this.setState({
                affiliateList: [{ key: 0, value: 0, text: 'Select Affiliate' }].concat(affiliateFromAPI),
                affiliateSelected: ((affiliateFromAPI.length > 0) ? affiliateFromAPI[0].value : 0),
                affiliateSelectedName: ((affiliateFromAPI.length > 0) ? affiliateFromAPI[0].text : '')
            }, () => {
                this.setState({
                    affiliateLoaded: true
                });
            });
        }).catch(error => {
            console.error(error);
        });
    }

    render() {
        if (this.state.authenticating) {
            return (
                <div style={{ height: "100vh", backgroundColor: "dodgerblue", color: "white" }}>
                    <span style={{ fontSize: "20px", color: "white", paddingLeft: "10px" }}>Historical<b>Data@PMI</b></span>
                    <div style={{ width: "150px", paddingTop: "250px", textAlign: "center", margin: "0 auto" }} >
                        <div className="spinner-border"></div><div>Authenticating...</div>
                    </div>
                </div>
            );
        }
        else if (this.state.authenticated && this.state.affiliateSelected > 0) {
            return (
                <Router>
                    <div>
                        <NavMenu affiliateList={this.state.affiliateList} affiliateSelectedName={this.state.affiliateSelectedName} affiliateSelected={this.state.affiliateSelected}  setAffiliate={this.setAffiliate} callAPI={this.callAPI} executeLogout={this.executeLogout} />
                        <Switch>
                            <Route path="/" exact component={() => <Search bearerToken={this.state.bearerToken} affiliateSelected={this.state.affiliateSelected} affiliateSelectedName={this.state.affiliateSelectedName} callAPI={this.callAPI} />} />
                            <Route path="/search" component={() => <Search bearerToken={this.state.bearerToken} affiliateSelected={this.state.affiliateSelected} affiliateSelectedName={this.state.affiliateSelectedName} callAPI={this.callAPI} />} />
                        </Switch>
                    </div>
                </Router>
            );
        }
        else if (this.state.authenticated && this.state.affiliateLoaded === true && this.state.affiliateSelected === 0) {
            return (
                <div style={{ height: "100vh", backgroundColor: "dodgerblue", color: "white" }}>
                    <span style={{ fontSize: "20px", color: "white", paddingLeft: "10px" }}>Historical<b>Data@PMI</b></span>
                    <div style={{ width: "100%", paddingTop: "200px", textAlign: "center", margin: "0 auto" }} >
                        <span style={{ color: "white" }}>Unauthorized Access : Valid Historical Data IMDL role required. Please contact your administrator.</span>
                        <div style={{ width: "150px", paddingTop: "50px", textAlign: "center", margin: "0 auto" }} >
                            <button className="btn btn-block btn-outline-dark" id="logoutLink" onClick={this.executeLogout}>Sign Out</button>
                        </div>
                    </div>
                </div>
            );
        }
        else if (this.state.authenticated && this.state.affiliateLoaded === false) {
            return (
                <div style={{ height: "100vh", backgroundColor: "dodgerblue", color: "white" }}>
                    <span style={{ fontSize: "20px", color: "white", paddingLeft: "10px" }}>Historical<b>Data@PMI</b></span>
                    <div style={{ width: "150px", paddingTop: "250px", textAlign: "center", margin: "0 auto" }} >
                        <div className="spinner-border"></div><div>Loading...</div>
                    </div>
                </div>
            );
        }
        else {
            return (
                <div style={{ height: "100vh", backgroundColor: "dodgerblue", color: "white" }}>
                    <span style={{ fontSize: "20px", color: "white", paddingLeft: "10px" }}>Historical<b>Data@PMI</b></span>
                    <div style={{ width: "150px", paddingTop: "250px", textAlign: "center", margin: "0 auto" }} >
                        <a className="btn btn-block btn-outline-dark" id="loginLink" href={loginUrl}>Login</a>
                    </div>
                </div>
            );
        }
    }
}
