/*
Author: Kamakhya Pandey
Created: 
Last updated: 02-Feb-2021
Purpose: ToolBar Component
ToolBar component provides the user with the following functionalities in the Visualizer:
Pagination: User can traverse through the pages in the document. 
    Displays Current Page Number and Total Pages in the document
    Actions-
    First Page: Go to First Page
    Previous Page: Go to Previous Page
    Next Page: Go to Next Page
    Last Page: Go to Last Page
    Go To Page: Go to a specific page in the document
Search : User can search for a specific text within the current page. 
    Search functionality is not supported for all file types (.pdf). 
    Case matching is also available. 
    All matching occurances are highlighted in yellow background. 
    Information about total matches found and currently highlighted occurance is also displayed.
    Actions-
    Match case checkbox: Option for case-sensitive or case-insensitive search
    Previous Match: Highlight previous occurance in orange background.
    Next Match: Highlight next occurance in orange background.
Zoom: Zooming options are available for all file types with 25% zoom step.
    Some file types (pdf) might not display text properly (blurriness) when using zoom features.
    Actions-
    Zoom-In : Zoom in page content upto 200%
    Zoom-Out : Zoom out page contect upto 25%
    Zoom-Reset : Reset zoom to 100%
Download Page: Download the current page in PDF format.
    Pages of PDF document can be downloaded in PDF format with same page size
    Other documents can be downloaded in PDF format in A1 page size
*/
import React, { Component } from 'react';
import './css/font-awesome.css';
import './css/ToolBar.css';

export default class ToolBar extends Component {
    state = {
        goToPageNo: 0
    }

    componentDidMount() {
        //console.log(this.props.visualizerDisableSearch);
    }

    validateGoToPage = (e) => {
        const regexp = /^[0-9\b]+$/;

        if (e.target.value === '' || regexp.test(e.target.value)) {
            this.setState({
                goToPageNo: e.target.value
            })
        }
    }

    handleGoToPage = () => {
        if (this.state.goToPageNo >= 1 && this.state.goToPageNo <= this.props.totalPages) {
            this.props.goToPage(this.state.goToPageNo);
        }
        else {
            alert("Go to page must be a valid page number!")
            this.setState(prevState => ({
                goToPageNo: ((prevState.goToPageNo < 1) ? 1 : ((prevState.goToPageNo > this.props.totalPages) ? this.props.totalPages : prevState.goToPageNo))
            }))
        }
    }

    render() {
        const pagePosCount = "Page " + this.props.currentPageNo + " of " + this.props.totalPages;
        const matchPosCount = this.props.visualizerSearchCurrentMatchPosition + "/" + this.props.visualizerSearchMatchCount;
        return (
            <div className="tool-bar">
                <div id="paginationButtons" className="menuSection">
                    <button title="First Page" onClick={this.props.firstPage} disabled={this.props.srLoading}><i className="iconButton  fa fa-step-backward"></i></button>
                    <button title="Previous Page" onClick={this.props.prevPage} disabled={this.props.srLoading}><i className="iconButton fa fa-chevron-left"></i></button>
                </div>
                <div id="pageInfo" className="menuSection">
                    <label className="pagePosCount">{pagePosCount}</label>
                </div>
                <div id="paginationButtons" className="menuSection">
                    <button title="Next Page" onClick={this.props.nextPage} disabled={this.props.srLoading}><i className="iconButton fa fa-chevron-right"></i></button>
                    <button title="Last Page" onClick={this.props.lastPage} disabled={this.props.srLoading}><i className="iconButton fa fa-step-forward"></i></button>
                </div>
                <div id="goToPage" className="menuSection">
                    <label>Go To Page :</label>
                    <input type="textbox" id="goToPageNumber" className="pageField" disabled={this.props.srLoading} value={((this.state.goToPageNo > 0) ? this.state.goToPageNo : "")} onChange={this.validateGoToPage}></input>
                    <button title="Go to page" onClick={this.handleGoToPage} disabled={this.props.srLoading}><i className="iconButton fa fa-arrow-circle-o-right"></i></button>
                </div>
                <div id="searchOption" className="menuSection">
                    <label>Find :</label>
                    <input type="textbox" id="searchText" className="pageField searchText" disabled={this.props.visualizerDisableSearch || this.props.srLoading} onBlur={this.props.handleVisualizerSearchTextChange}></input>
                    <input type="checkbox" id="matchCase" title="Match case" className="matchCase" disabled={this.props.visualizerDisableSearch || this.props.srLoading} onChange={this.props.setVisualizerSearchMatchCase} />
                    <label htmlFor="matchCase" className="matchCaseLabel">Match case</label>
                    <button title="Search" onClick={this.props.textSearch} disabled={this.props.visualizerDisableSearch || this.props.srLoading}><i className="iconButton fa fa-search"></i></button>
                    <label className="matchPosCount">{matchPosCount}</label>
                    <button title="Previous match" onClick={this.props.prevMatch} disabled={this.props.visualizerDisableSearch || this.props.srLoading} ><i className="iconButton fa fa-chevron-left"></i></button>
                    <button title="Next match" onClick={this.props.nextMatch} disabled={this.props.visualizerDisableSearch || this.props.srLoading} ><i className="iconButton fa fa-chevron-right"></i></button>
                </div>
                <div id="zoomOption" className="menuSection">
                    <button title="Zoom-In" onClick={this.props.zoomIn} disabled={this.props.srLoading}><i className="iconButton fa fa-search-plus"></i></button>
                    <button title="Zoom-Out" onClick={this.props.zoomOut} disabled={this.props.srLoading}><i className="iconButton fa fa-search-minus"></i></button>
                    <button title="Reset zoom" onClick={this.props.zoomReset} disabled={this.props.srLoading}><i className="iconButton fa fa-undo"></i></button>
                </div>
                <div id="zoomOption" className="menuSection">
                    <button title="Download This Page" onClick={this.props.downloadFilePage} disabled={this.props.srLoading}><i className="iconButton fa fa-cloud-download"></i></button>
                </div>
            </div>
        )
    }
}
/*
                    <label>Page</label>
                    <label id="pageNumber" className="pageField" disabled={this.props.srLoading}>{this.props.currentPageNo}</label>
                    <label>of</label>
                    <label id="totalPages" className="pageField" disabled={this.props.srLoading}>{this.props.totalPages}</label>
*/